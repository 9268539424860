<template>
  <div class="advReport">
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="filter" size="mini">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Date" label-width="60px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  align="right"
                  unlink-panels
                  :clearable="false"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="OfferId(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="filter.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="offer_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Source(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <group-select
                      v-model="filter.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      reserve-keyword
                      :loading="sourceLoading"
                      clearable
                      filterable
                      collapse-tags
                      size="mini"
                      placeholder="Please select"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="source_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="affiliate(s):" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.affiliateIds"
                      :data-source="affiliatesGroupList"
                      class="w100"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      collapse-tags
                      size="mini"
                      placeholder="Please select Affiliate"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      style="color: #fff"
                      label="affiliate_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Countries" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input v-model="filter.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="country"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Package" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="filter.prods"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="prod"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="TimeZone" label-width="80px" class="mb5">
                <el-select v-model="filter.timezone" class="w100">
                  <el-option
                    v-for="item in timezoneOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Interval" class="mb5" label-width="80px">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Month" class="mb0">
                      <el-checkbox v-model="filterTimeStatus" label="month" class="color-white"
                        >.
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Date" class="mb0">
                      <el-checkbox v-model="filterTimeStatus" class="color-white" label="date"
                        >.
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Hour" class="mb0">
                      <el-checkbox v-model="filterTimeStatus" class="color-white" label="hour"
                        >.
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="pm(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="filter.pmList"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in sourceManagers"
                        :key="item.username"
                        :label="item.username"
                        :value="item.username"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="pm"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Platform(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-select
                      v-model="filter.platforms"
                      placeholder="Please select"
                      class="w100"
                      clearable
                      multiple
                    >
                      <el-option
                        v-for="item in options.platforms"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="platforms"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="sub rule:" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-select
                      v-model="filter.subRuleIds"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                      clearable
                    >
                      <el-option
                        v-for="item in options.subRule"
                        :key="item.ruleId"
                        :label="item.ruleName"
                        :value="item.ruleId"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="sub_rule_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="pid(s)" label-width="80px" class="mb5">
                <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                  <el-select
                    v-model="filter.pids"
                    multiple
                    filterable
                    collapse-tags
                    placeholder="Please select"
                    class="w100"
                    clearable
                  >
                    <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                  <el-checkbox v-model="filterCheckedStatus" class="color-white" label="pid"
                    >.
                  </el-checkbox>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="aff sub:" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="filter.affSubs"
                      placeholder="eg. carry_i or carry_i,carry_j"
                      clearable
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2">
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      class="color-white"
                      label="affiliate_sub"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="offer type" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="filter.offerTypes"
                      placeholder="Please select"
                      multiple
                      collapse-tags
                      class="w100"
                      clearable
                    >
                      <el-option label="rt" value="rt"></el-option>
                      <el-option label="ua" value="ua"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      class="color-white"
                      label="offer_type"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="import" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="filter.appImportants"
                      placeholder="Please select"
                      multiple
                      collapse-tags
                      class="w100"
                      clearable
                    >
                      <el-option label="important" value="important"></el-option>
                      <el-option label="unImportant" value="unImportant"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      class="color-white"
                      label="app_important"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="mmp" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="filter.mmps"
                      placeholder="Please select"
                      multiple
                      collapse-tags
                      class="w100"
                      clearable
                    >
                      <el-option label="af" value="af"></el-option>
                      <el-option label="adjust" value="adjust"></el-option>
                      <el-option label="branch" value="branch"></el-option>
                      <el-option label="unKnow" value="unKnow"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="filterCheckedStatus" class="color-white" label="mmp"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" align="right">
              <el-button
                type="primary"
                size="mini"
                :loading="listLoading"
                @click="searchListClick(1)"
                >In Browser
              </el-button>
              <el-button type="primary" size="mini" @click="exportExcelFileClick"
                >Download Excel
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          size="mini"
          stripe
          border
          ref="tableRef"
          :data="advReportList"
          :default-sort="{ prop: 'insRejectTotal', order: 'descending' }"
          @sort-change="sortChange"
          highlight-current-row
        >
          <el-table-column label="Date" prop="date" width="100" align="center" key="date">
            <template v-slot="scope">
              {{ formatDate(scope.row.date, 'month') }}
            </template>
          </el-table-column>
          <el-table-column
            label="Source ID"
            prop="sourceId"
            v-if="filterCheckedStatus.indexOf('source_id') != -1"
            width="90"
            align="center"
            key="sourceId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.source_id
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            prop="sourceName"
            v-if="filterCheckedStatus.indexOf('source_id') != -1"
            width="110"
            align="center"
            key="sourceName"
          ></el-table-column>
          <el-table-column
            label="Offer ID"
            width="80"
            align="center"
            v-if="filterCheckedStatus.indexOf('offer_id') != -1"
            key="offerId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offer_id
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Name"
            width="100"
            align="center"
            v-if="filterCheckedStatus.indexOf('offer_id') != -1"
            key="offerName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offer_name
              }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column
                      label="Offer Status"
                      prop="offerStatus"
                      width="105"
                      align="center"
                      v-if="filterCheckedStatus.indexOf('offer_id') != -1 "
                      key="offerStatus"
                    ></el-table-column>-->
          <el-table-column
            label="affiliateId"
            prop="affiliate_id"
            width="105"
            align="center"
            v-if="filterCheckedStatus.indexOf('affiliate_id') != -1"
            key="affiliate_id"
          ></el-table-column>
          <el-table-column
            label="affiliateName"
            prop="affiliate_name"
            width="105"
            align="center"
            v-if="filterCheckedStatus.indexOf('affiliate_id') != -1"
            key="affiliate_name"
          ></el-table-column>
          <el-table-column
            label="Prod Name"
            prop="prod"
            width="100"
            align="center"
            v-if="filterCheckedStatus.indexOf('prod') != -1"
            key="pkg"
          ></el-table-column>
          <el-table-column
            label="Country"
            prop="country"
            width="80"
            align="center"
            v-if="filterCheckedStatus.indexOf('country') != -1"
            key="country"
          ></el-table-column>
          <el-table-column
            label="pm"
            prop="pm"
            width="90"
            align="center"
            v-if="filterCheckedStatus.indexOf('pm') != -1"
            key="pm"
          >
            <!--            <template slot="header"> Sources<br />Manager </template>-->
          </el-table-column>
          <el-table-column
            label="affSub"
            prop="affiliate_sub"
            width="90"
            align="center"
            v-if="filterCheckedStatus.indexOf('affiliate_sub') != -1"
            key="affiliate_sub"
          >
          </el-table-column>
          <el-table-column
            label="platforms"
            prop="platforms"
            width="90"
            align="center"
            v-if="filterCheckedStatus.indexOf('platforms') != -1"
            key="platforms"
          >
          </el-table-column>
          <el-table-column
            label="subRuleId"
            prop="sub_rule_id"
            width="90"
            align="center"
            v-if="filterCheckedStatus.indexOf('sub_rule_id') != -1"
            key="subRuleId"
          >
          </el-table-column>
          <el-table-column
            label="subRuleName"
            prop="subRuleName"
            width="90"
            align="center"
            v-if="filterCheckedStatus.indexOf('sub_rule_id') != -1"
            key="subRuleId"
          >
          </el-table-column>
          <el-table-column
            label="pid"
            width="100"
            prop="pid"
            v-if="filterCheckedStatus.indexOf('pid') != -1"
            align="center"
            key="pid"
          ></el-table-column>
          <el-table-column
            label="offerType"
            width="100"
            prop="offer_type"
            v-if="filterCheckedStatus.indexOf('offer_type') != -1"
            align="center"
            key="offerType"
          ></el-table-column>
          <el-table-column
            label="appImportant"
            width="100"
            prop="app_important"
            v-if="filterCheckedStatus.indexOf('app_important') != -1"
            align="center"
            key="appImportant"
          ></el-table-column>
          <el-table-column
            label="MMP"
            width="100"
            prop="mmp"
            v-if="filterCheckedStatus.indexOf('mmp') != -1"
            align="center"
            key="mmp"
          ></el-table-column>
          <el-table-column
            label="Payout"
            min-width="70"
            show-overflow-tooltip
            align="center"
            key="lastTotalPayout"
            prop="payout"
            sortable="custom"
          >
            <!--            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    scope.row.totalPayout,
                    new Number(scope.row.lastTotalPayout).toFixed(2)
                  )
                "
              ></span>
            </template>-->
          </el-table-column>
          <el-table-column
            label="Revenue"
            min-width="80"
            show-overflow-tooltip
            align="center"
            key="lastTotalRevenue"
            prop="revenue"
            sortable="custom"
          >
            <!--            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    scope.row.totalRevenue,
                    new Number(scope.row.lastTotalRevenue).toFixed(2)
                  )
                "
              ></span>
            </template>-->
          </el-table-column>
          <el-table-column
            label="installs"
            min-width="80"
            align="center"
            key="installs"
            prop="installs"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="insRejectTotal"
            min-width="80"
            align="center"
            prop="insRejectTotal"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="installRejectRate"
            min-width="80"
            align="center"
            prop="installRejectRate"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ (scope.row.installRejectRate * 100).toFixed(3) }}%
            </template>
          </el-table-column>
          <el-table-column
            label="clickFloods"
            min-width="80"
            align="center"
            prop="clickFloods"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="ai_layer"
            min-width="80"
            align="center"
            prop="ai_layer"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="fake_device_parameters"
            min-width="80"
            align="center"
            prop="fake_device_parameters"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="installHijackings"
            min-width="80"
            align="center"
            prop="installHijackings"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <!--          <el-table-column
            label="bots"
            min-width="80"
            align="center"
            prop="bots"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>-->
          <el-table-column
            label="botsTimestamp"
            min-width="80"
            align="center"
            prop="botsTimestamp"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="botsBayesian"
            min-width="80"
            align="center"
            prop="botsBayesian"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="botsDevice"
            min-width="80"
            align="center"
            prop="botsDevice"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="behavioralAnomalies"
            min-width="80"
            align="center"
            prop="behavioralAnomalies"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="botsReasonBehavioralAnomalies"
            min-width="80"
            align="center"
            prop="subReasonBehavioralAnomalies"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="siteBlacklists"
            min-width="80"
            align="center"
            prop="siteBlacklists"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="validationBots"
            min-width="80"
            align="center"
            prop="validationBots"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="botsFakeInstall"
            min-width="80"
            align="center"
            prop="botsFakeInstall"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="validationHijacking"
            min-width="80"
            align="center"
            prop="validationHijacking"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="ctitAnomalies"
            min-width="80"
            align="center"
            prop="ctitAnomalies"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="inappsBots"
            min-width="80"
            align="center"
            prop="inappsBots"
            sortable="custom"
            v-if="onlyAdjustBranch()"
          ></el-table-column>
          <el-table-column
            label="anonymousTraffic"
            min-width="80"
            align="center"
            prop="anonymousTraffic"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="distributionOutlier"
            min-width="80"
            align="center"
            prop="distributionOutlier"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="tooManyEngagements"
            min-width="80"
            align="center"
            prop="tooManyEngagements"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="engagementInjection"
            min-width="80"
            align="center"
            prop="engagementInjection"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="incorrectSignature"
            min-width="80"
            align="center"
            prop="incorrectSignature"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="invalidSignature"
            min-width="80"
            align="center"
            prop="invalidSignature"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="malformedAdvertisingId"
            min-width="80"
            align="center"
            prop="malformedAdvertisingId"
            sortable="custom"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import AdvReportCon from '../controllers/report/RejectReport';

  export default {
    name: 'AdvReport',
    ...AdvReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
